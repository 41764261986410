// .js-accordion-trigger
class Accordion {
    constructor(node) {
        this.node = node;
        this.button = this.node.querySelector(".accordion__button");
        this.body = this.node.querySelector(".accordion__body");
        this.isOpen = false;

        this.events();
    }

    events() {
        this.body.style.maxHeight = `${0}px`;
        this.button.addEventListener("click", this.handleClick.bind(this));
    }

    handleClick() {
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    close() {
        this.isOpen = false;
        this.node.classList.remove("is-active");
        this.button.classList.remove("is-active");
        this.node.classList.add("is-hidden");
        this.body.style.maxHeight = `${0}px`;
        this.button.setAttribute("aria-expanded", false);
    }

    open() {
        this.isOpen = true;
        this.node.classList.add("is-active");
        this.button.classList.add("is-active");
        this.node.classList.remove("is-hidden");
        this.body.style.maxHeight = `${this.body.scrollHeight}px`;
        this.button.setAttribute("aria-expanded", true);
    }
}

export default Accordion;