import $ from 'jquery';
// not used - skipLinks.js
import skipLinks from './utils/skipLinks';
import mNav from './utils/mNav';
import childTabIndex from './utils/childTabIndex';
// not used - searchExpand.js
import Accordion from './utils/accordion';
import searchExpand from './utils/searchExpand';
import setupSharing from './utils/social';
import gaExtras from './utils/gaExtras';
import equalHeight from './utils/equalHeight';
import domAppend from './utils/domAppend';
import listShowHide from './utils/listShowHide';
import setupGDPR from './utils/setupGDPR';
import setupNotices from './utils/setupNotices';
import setupSelect2 from './utils/setupSelect2';
import listFilter from './utils/listFilter';
import setupIntegerCountup from './utils/integerCountup';
import subNav from './utils/subnavigation';

// not used? - iframer.js
// not used? - widont.js

function globals () {

    // Small Screen Navigation
    mNav(
        '#navigation-primary-toggle',
        'navigation-primary-toggle--open',
        '#navigation-primary__items',
        'navigation-primary__items--open'
    );

    // Inpage Navigation
    mNav(
        '#aside__navigation-toggle',
        'aside__navigation-toggle--open',
        '.aside__navigation',
        'aside__navigation--open'
    );

    // Set child tab index for nav
    childTabIndex(
        '#navigation-primary-toggle',
        '#navigation-primary',
        'navigation-primary--active',
        960
    );

    // Search showHide
    mNav(
        '#header-search-toggle',
        'header-search-toggle--open',
        '#header-search',
        'header-search--open'
    );

    // Filter showHide
    mNav(
        '#filter-trigger',
        'filter__heading--open',
        '#filter-reveal',
        'filter__reveal--open'
    );

    // Append element
    domAppend(
        '#js-inpage-nav',
        '#append-nav-mobile',
        '#append-nav-desktop',
        900
    );

    // Set child tab index for nav
    childTabIndex(
        '.filterBlock__title',
        '.filters__wrap',
        'active',
        780
    );

    subNav();

    // select2
    setupSelect2();

    // list filter
    listFilter();

    // list toggles
    listShowHide();

    // social
    setupSharing();

    // set up notices
    setupGDPR();

    // set up notices
    setupNotices();

    // set integer countup
    setupIntegerCountup();

    // equal height chart titles
    equalHeight('.l-2up, .l-3up, .l-4up, .l-2-3up', '.m-intro__subheading');

    gaExtras(
        '.js-ga-download-click, .js-social-share, .js-ga-event-link'
    );
}

$(function run () {
    console.log('ᕕ( ᐛ )ᕗ Running...');
    globals();

    // JS FOR ACCORDION
    if (document.querySelector(".accordion")) {
        let accordionBlocks = [...document.querySelectorAll(".accordion")];
        accordionBlocks.forEach((block) => {
            new Accordion(block);
        });
    }

});
